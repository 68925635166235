<template>
  <div :class="filterToggleState + 'usedCars-section'">
    <div class="filter-header row">
      <div class="pointer row filter-label" @click="toggleContainer('filter')">
        <p>Filtros</p>
        <Arrow />
      </div>
      <div>
        <p>
          <span>{{ count }}</span> Opções
        </p>
      </div>
      <div
        class="pointer row order-label"
        tabindex="0"
        @focusout="orderToggleState = false"
        @click="orderToggleState = !orderToggleState"
      >
        {{ selectedOrder }}<Arrow />
        <div v-if="orderToggleState" class="option-panel">
          <div
            class="custom-option"
            @click="
              changeOrder('price', 'asc', 'Ordenar por Preço (Crescente)')
            "
          >
            Ordenar por Preço (Crescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder('price', 'desc', 'Ordenar por Preço (Decrescente)')
            "
          >
            Ordenar por Preço (Decrescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder(
                'monthlyPayment',
                'asc',
                'Ordenar por Renda (Crescente)'
              )
            "
          >
            Ordenar por Renda (Crescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder(
                'monthlyPayment',
                'desc',
                'Ordenar por Renda (Decrescente)'
              )
            "
          >
            Ordenar por Renda (Decrescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder('km', 'asc', 'Ordenar por Quílometros (Crescente)')
            "
          >
            Ordenar por Quílometros (Crescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder('km', 'desc', 'Ordenar por Quílometros (Decrescente)')
            "
          >
            Ordenar por Quílometros (Decrescente)
          </div>
          <div
            class="custom-option"
            @click="changeOrder('year', 'asc', 'Ordenar por Ano (Decrescente)')"
          >
            Ordenar por Ano (Crescente)
          </div>
          <div
            class="custom-option"
            @click="
              changeOrder('year', 'desc', 'Ordenar por Ano (Decrescente)')
            "
          >
            Ordenar por Ano (Decrescente)
          </div>
        </div>
      </div>
    </div>

    <div class="filter-container" v-if="getAllFilters.model">
      <ActiveFilter
        :activeFilters="activeFilters"
        :appliedFilters="appliedFilters"
        @onRemoveFilter="removeFilter($event)"
        @onRemoveAllFilterType="removeType"
        @removeAllFilter="removeAllFilter"
      />
      <FilterOptions
        :filters="getAllFilters"
        @checkboxFilterReplace="checkboxFilterReplace"
        @checkboxFilter="checkboxFilter"
        @rangeFilter="rangeFilter"
        @removeAllFilter="removeAllFilter"
        ref="filterOptions"
      />
    </div>
    <UsedCarsSection
      :data="usedCars"
      page="usados"
      :filterToggle="filterToggleState == 'filter-close ' ? false : true"
    />

    <div
      :class="
        filterToggleState == 'filter-close '
          ? 'button-wrapper-closed'
          : 'button-wrapper'
      "
      v-if="isShowMore"
    >
      <button class="show-more" @click="showMore">Mostrar Mais</button>
    </div>
  </div>
</template>

<script>
import ActiveFilter from "@/components/FilterUsedCars/ActiveFilter.vue";
import Arrow from "@/assets/Arrow.vue";
import FilterOptions from "@/components/FilterUsedCars/FilterOptions.vue";
import UsedCarsSection from "@/components/UsedCars/UsedCarsSection.vue";
import { filterCar } from "@/services/functions.js";

export default {
  name: "FilterUsedCars",
  components: {
    ActiveFilter,
    Arrow,
    FilterOptions,
    UsedCarsSection,
  },
  data() {
    return {
      filterToggleState: "filter-close ",
      orderToggleState: false,
      usedCars: [],
      initialUsedCars: [],
      initialValues: [],
      labelValues: [],
      activeFilters: [],
      steps: [],
      dealerSelected: this.$route.params.dealer,
      page: 1,
      update: 0,
      order: {},
      selOrder: "Ordenar por Preço (Crescente)",
    };
  },
  computed: {
    count() {
      return this.$store.state.usadosCount;
    },
    isShowMore() {
      return this.$store.state.usadosShowMore;
    },
    appliedFilters() {
      return this.$store.state.appliedFilter;
    },
    getAllFilters() {
      return this.$store.state.filtersUsados;
    },
    selectedOrder() {
      return this.selOrder;
    },
  },
  watch: {
    "$store.state.usados": function () {
      this.usedCars = this.$store.getters.getNewUsedCarsArray;
      this.update++;
    },
  },
  created() {
    this.removeAllFilter();
  },

  methods: {
    filterCar,
    changeOrder(type, order, newSel) {
      this.order = { type, order };
      this.selOrder = newSel;
      this.getNewData();
    },
    showMore() {
      this.page++;
      this.$store.commit("SET_LOADING", true);
      this.filterCar(
        { filters: this.$store.state.appliedFilters, order: {} },
        this.page
      ).then((response) => {
        this.$store.commit("ADD_USADOS", {
          data: response.data.searchResult,
          isShowMore: response.pagination.totalResults,
        });
        this.$store.commit("SET_LOADING", false);
      });
    },

    loadContent() {
      if (this.$route.params.dealer) {
        this.getAllFilters.dealer.forEach((element) => {
          if (element.id == this.$route.params.dealer) {
            this.$store.commit("ADD_FILTER_CHECKBOX", element);
            this.getNewData();
          }
        });
      } else {
        this.getNewData();
      }
    },
    toggleContainer(container) {
      switch (container) {
        case "filter":
          this.filterToggleState =
            this.filterToggleState == "" ? "filter-close " : "";
          break;
        default:
      }
    },
    checkboxFilterReplace(event) {
      if (event.value.checked == true) {
        let inputs = document.getElementsByTagName("input");
        for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].className == "checkbox") {
            inputs[i].checked = false;
          }
        }
        this.$store.commit("REMOVE_ALL_FILTER", 1);
        let checkBox = document.getElementById("model" + event.type.id);
        checkBox.checked = true;
        this.$store.commit("ADD_FILTER_CHECKBOX", event.type);
      } else {
        this.$store.commit("REMOVE_FILTER", event.type);
      }

      this.getNewData();
    },
    checkboxFilter(event) {
      if (event.value.checked == true) {
        this.$store.commit("ADD_FILTER_CHECKBOX", event.type);
      } else {
        this.$store.commit("REMOVE_FILTER", event.type);
      }

      this.getNewData();
    },
    // FUNCTION CALLED WHEN USER CHANGES ANY SETTING ON THE FILTER WITH RANGES
    rangeFilter(event) {
      this.$store.commit("ADD_FILTER_RANGE", event);
      this.getNewData();
    },
    // FUNCTION CALLED WHEN USER CLEARS ONE FILTER
    removeFilter: function (prop) {
      let but = document.getElementById(prop.type + prop.id);
      if (but) {
        if (but.checked) {
          but.checked = false;
        }
      }
      if (prop.type == "price") {
        let priceSup = document.getElementById("priceSup_sliderMax");
        let priceInf = document.getElementById("priceInf_sliderMin");
        let priceSupInput = document.getElementById("priceSup_inputMax");
        let priceInfInput = document.getElementById("priceInf_inputMin");
        if (priceSup && priceInf) {
          priceSup.value = priceSup.max;
          priceInf.value = priceInf.min;
          priceSupInput.firstElementChild.value = priceSup.max;
          priceInfInput.firstElementChild.value = priceInf.min;
        }
        if (this.$refs.filterOptions) {
          this.$refs.filterOptions.resetRange("price");
        }
      }
      if (prop.type == "fee") {
        let feeSup = document.getElementById("monthlyPaymentSup_sliderMax");
        let feeInf = document.getElementById("monthlyPaymentInf_sliderMin");
        let feeSupInput = document.getElementById("monthlyPaymentSup_inputMax");
        let feeInfInput = document.getElementById("monthlyPaymentInf_inputMin");
        if (feeSup && feeInf) {
          feeSup.value = feeSup.max;
          feeInf.value = feeInf.min;
          feeSupInput.firstElementChild.value = feeSup.max;
          feeInfInput.firstElementChild.value = feeInf.min;
        }
        if (this.$refs.filterOptions) {
          this.$refs.filterOptions.resetRange("fee");
        }
      }
      if (prop.type == "year") {
        let yearSup = document.getElementById("yearSup_sliderMax");
        let yearInf = document.getElementById("yearMin_sliderMin");
        let yearSupInput = document.getElementById("yearSup_inputMax");
        let yearMinInput = document.getElementById("yearMin_inputMin");
        if (yearSup && yearInf) {
          yearSup.value = yearSup.max;
          yearInf.value = yearInf.min;
          yearSupInput.firstElementChild.value = yearSup.max;
          yearMinInput.firstElementChild.value = yearInf.min;
        }
        if (this.$refs.filterOptions) {
          this.$refs.filterOptions.resetRange("year");
        }
      }
      if (prop.type == "kms") {
        let kmSup = document.getElementById("kmSup_sliderMax");
        let kmInf = document.getElementById("kmInf_sliderMin");
        let kmSupInput = document.getElementById("kmSup_inputMax");
        let kmInfInput = document.getElementById("kmInf_inputMin");
        if (kmSup && kmInf) {
          kmSup.value = kmSup.max;
          kmInf.value = kmInf.min;
          kmSupInput.firstElementChild.value = kmSup.max;
          kmInfInput.firstElementChild.value = kmInf.min;
        }
        if (this.$refs.filterOptions) {
          this.$refs.filterOptions.resetRange("kms");
        }
      }

      this.$store.commit("REMOVE_FILTER", prop);
      this.getNewData();
    },
    removeType: function (arr) {
      arr.forEach((element) => {
        let but = document.getElementById(element.type + element.id);
        if (but) {
          if (but.checked) {
            but.checked = false;
          }
        }
        this.$store.commit("REMOVE_FILTER", element);
      });
      this.getNewData();
    },
    // FUNCTION CALLED WHEN USER CLEARS ALL FILTERS
    removeAllFilter: function () {
      let inputs = document.getElementsByTagName("input");
      for (let i = 0; i < inputs.length; i++) {
        if (inputs[i].className == "checkbox") {
          inputs[i].checked = false;
        }
      }
      let priceSup = document.getElementById("priceSup_sliderMax");
      let priceInf = document.getElementById("priceInf_sliderMin");
      let feeSup = document.getElementById("monthlyPaymentSup_sliderMax");
      let feeInf = document.getElementById("monthlyPaymentInf_sliderMin");
      let yearSup = document.getElementById("yearSup_sliderMax");
      let yearInf = document.getElementById("yearMin_sliderMin");
      let kmSup = document.getElementById("kmSup_sliderMax");
      let kmInf = document.getElementById("kmInf_sliderMin");
      if (priceSup && priceInf) {
        priceSup.value = priceSup.max;
        priceInf.value = priceInf.min;
      }
      if (feeSup && feeInf) {
        feeSup.value = feeSup.max;
        feeInf.value = feeInf.min;
      }
      if (yearSup && yearInf) {
        yearSup.value = yearSup.max;
        yearInf.value = yearInf.min;
      }
      if (kmSup && kmInf) {
        kmSup.value = kmSup.max;
        kmInf.value = kmInf.min;
      }
      if (this.$refs.filterOptions) {
        this.$refs.filterOptions.resetRange();
      }
      this.$store.commit("REMOVE_ALL_FILTER", 2);
      this.loadContent();
    },
    async getNewData() {
      this.$store.commit("SET_LOADING", true);

      let orderValues = {};
      if (this.order) {
        orderValues = this.order;
      }

      await Promise.all([
        this.$store.dispatch("getNewFilters", {
          condition: 2,
          filters: this.$store.state.appliedFilters,
        }),
        this.$store.dispatch("getSearchedUsed", {
          filters: this.$store.state.appliedFilters,
          order: orderValues,
        }),
      ]);
      if (this.$route.params.dealer) {
        document.getElementById(
          "dealer" + this.$route.params.dealer
        ).checked = true;
      }
      this.$store.commit("SET_LOADING", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/FilterUsedCars";
</style>
