var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.filterToggleState + 'usedCars-section'},[_c('div',{staticClass:"filter-header row"},[_c('div',{staticClass:"pointer row filter-label",on:{"click":function($event){return _vm.toggleContainer('filter')}}},[_c('p',[_vm._v("Filtros")]),_c('Arrow')],1),_c('div',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.count))]),_vm._v(" Opções ")])]),_c('div',{staticClass:"pointer row order-label",attrs:{"tabindex":"0"},on:{"focusout":function($event){_vm.orderToggleState = false},"click":function($event){_vm.orderToggleState = !_vm.orderToggleState}}},[_vm._v(" "+_vm._s(_vm.selectedOrder)),_c('Arrow'),(_vm.orderToggleState)?_c('div',{staticClass:"option-panel"},[_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder('price', 'asc', 'Ordenar por Preço (Crescente)')}}},[_vm._v(" Ordenar por Preço (Crescente) ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder('price', 'desc', 'Ordenar por Preço (Decrescente)')}}},[_vm._v(" Ordenar por Preço (Decrescente) ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder(
              'monthlyPayment',
              'asc',
              'Ordenar por Renda (Crescente)'
            )}}},[_vm._v(" Ordenar por Renda (Crescente) ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder(
              'monthlyPayment',
              'desc',
              'Ordenar por Renda (Decrescente)'
            )}}},[_vm._v(" Ordenar por Renda (Decrescente) ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder('km', 'asc', 'Ordenar por Quílometros (Crescente)')}}},[_vm._v(" Ordenar por Quílometros (Crescente) ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder('km', 'desc', 'Ordenar por Quílometros (Decrescente)')}}},[_vm._v(" Ordenar por Quílometros (Decrescente) ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder('year', 'asc', 'Ordenar por Ano (Decrescente)')}}},[_vm._v(" Ordenar por Ano (Crescente) ")]),_c('div',{staticClass:"custom-option",on:{"click":function($event){_vm.changeOrder('year', 'desc', 'Ordenar por Ano (Decrescente)')}}},[_vm._v(" Ordenar por Ano (Decrescente) ")])]):_vm._e()],1)]),(_vm.getAllFilters.model)?_c('div',{staticClass:"filter-container"},[_c('ActiveFilter',{attrs:{"activeFilters":_vm.activeFilters,"appliedFilters":_vm.appliedFilters},on:{"onRemoveFilter":function($event){return _vm.removeFilter($event)},"onRemoveAllFilterType":_vm.removeType,"removeAllFilter":_vm.removeAllFilter}}),_c('FilterOptions',{ref:"filterOptions",attrs:{"filters":_vm.getAllFilters},on:{"checkboxFilterReplace":_vm.checkboxFilterReplace,"checkboxFilter":_vm.checkboxFilter,"rangeFilter":_vm.rangeFilter,"removeAllFilter":_vm.removeAllFilter}})],1):_vm._e(),_c('UsedCarsSection',{attrs:{"data":_vm.usedCars,"page":"usados","filterToggle":_vm.filterToggleState == 'filter-close ' ? false : true}}),(_vm.isShowMore)?_c('div',{class:_vm.filterToggleState == 'filter-close '
        ? 'button-wrapper-closed'
        : 'button-wrapper'},[_c('button',{staticClass:"show-more",on:{"click":_vm.showMore}},[_vm._v("Mostrar Mais")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }