<template>
  <div class="wrapper main">
    <Section class="mb-0">
      <HeadingBlock :data="usedCarsContent" />
      <FilterUsedCars ref="usedCar" />
    </Section>

    <Section v-if="seo">
      <SEO
        :seo="seo.general"
        :banner="seo.general.seoBannerImage"
        :link="seo.general.seoBannerLink"
      />
    </Section>
  </div>
</template>

<script>
import FilterUsedCars from "@/components/FilterUsedCars/FilterUsedCars.vue";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";
import HeadingBlock from "@/components/Blocks/HeadingBlock.vue";

import { filterCar, getFiltersUsed } from "@/services/functions.js";
import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "Usados",
  metaInfo() {
    return manageSEO(this.seo, this.seoHomepage, false, this.dealerName);
  },
  components: {
    FilterUsedCars,
    Section,
    SEO,
    HeadingBlock,
  },
  data() {
    return {
      usedCarsData: this.$store.state.usados,
      dealerName: this.$store.state.dealerInfo.dealerName,
      page: 1,
      init: false,
    };
  },
  methods: {
    getFiltersUsed,
    filterCar,
    showMore() {
      this.page++;
      this.filterCar(this.$store.state.appliedFilter, this.page).then(
        (response) => {
          this.$store.commit("ADD_USADOS", {
            data: response.data.searchResult,
          });
          this.$refs.usedCar.addUsed();
        }
      );
    },
  },
  computed: {
    usedCarsContent() {
      return this.$store.state.usadosContent;
    },
    seo() {
      return this.$store.state.usadosSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo.general;
    },
  },
};
</script>
<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  .show-more {
    background-color: $primaryWhiteColor;
    color: $primaryColor;
    border: thin solid $primaryColor;
    font-family: $fontRegular;
    width: 50%;
    cursor: pointer;
    display: block;
    font-size: $mainFontSize;
    padding: 1rem 1.5rem;
    text-align: center;
    text-decoration: none;
    transition: 0.05s all ease;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
</style>
